@import "./vars-mixins.scss";

// USer
._container {
  .description {
    padding: 0 1.7rem;
  }

  .question {
    @include flex-wrap(column);
    width: 90vw;
    max-width: 900px;
    min-width: 310px;

    div.labels {
      @include flex-wrap(column, center, flex-start);
      label {
        @include flex-wrap(row, flex-start);
        font-size: 1.4rem;
      }
      input[type="radio"] {
        width: 1rem;
        height: 1rem;
        margin: 0rem 0rem 0 0;
      }
    }

    input[type="text"] {
      margin-bottom: 1rem;
      padding: 0.2rem;
      text-align: center;
    }
  }

  .question.innerbox/* , .gamePreview */ {
    max-width: 95.5%;
    border: 0.2rem solid $themeColor;
    padding: 0 2.5rem;
    margin-bottom: 1rem;
    border-radius: 3rem 0 3rem 0;
    background: white !important;
    filter: drop-shadow(-0.7rem 0.7rem 0 #f0ada5);
    h2:first-of-type {
      border-bottom: 0.2rem solid $themeColor;
      min-width: 100%;
    }
    @media (max-width: 450px) {
      filter: none;
    }
  }
}

.question.questionRow {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 24.5rem;
  input {
    margin: auto 0 auto auto;
  }
  &.twoLines {
    input {
      margin: 0 0 0 auto;
    }
  }
}

.box-questions-users {
  h2 {
    font-size: 1.5rem;
    text-align: left;
  }
}

header {
  #pytProgress {
    text-align: right;
    padding: 0 0.6rem 1.5rem 0;
    text-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.4);
    border: 0.1rem solid white;
    border-radius: 0.4rem 0 0.4rem 0;
    width: 100%;
    max-width: 20rem;
    min-width: 4rem;
    height: 40%;
    margin: 0 3vmin;
    --progress: 0%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.9) var(--progress),
      rgba(255, 255, 255, 0.3) var(--progress)
    );
  }
  .toRight.pyt {
    @include moveTo(
      right
    ); /* font-style:italic; font-size:1rem; font-weight:600; width:10rem; text-align:right; order:10; */
    img {
      width: 3.2rem;
      transform: translate(6%, 2%);
      filter: drop-shadow(1px 0.2rem 3px rgba(0, 0, 0, 0.55));
    }
  }
}

// Hide sequence svg on very small screens
.sequenceSVG {
  @media (max-width: 270px) {
    display: none;
  }
}
