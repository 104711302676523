@import "./vars-mixins.scss";

// Profile
._container {
  .titleAndDownload {
    @include flex-wrap;
    margin-top: 1rem;
    h1 {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  // pdf downloader with icon
  a.downloadProfile {
    text-align: center;
    #{$a} {
      color: $themeColor;
      text-decoration: none;
    }
    margin-top: -0.5rem;
    font-size: 1.4rem;
    &:before {
      content: "";
      width: 90vw;
      height: 1px;
      background: transparent;
      display: block;
    }
    img {
      width: 2rem;
      height: 2rem;
      margin-left: 0.6rem;
      margin-top: -0.3rem;
    }
  }

  // svg chart
  svg#newChart {
    max-width: #{"min(400px, 90vw)"};
    margin: 0 2rem;
    clip-path: polygon(
      0% 0%,
      70% 0%,
      100% 10%,
      100% 100%,
      0% 100%
    ); // clip-path is necessary to prevent the svg from blocking the link
    transform: rotate(-10deg);
    a:hover {
      filter: url(#dropshadow);
      cursor: pointer;
    }
  }

  // table with data about the area
  .areaTable {
    @include centerOverlay(both, fixed);
    @include obscureUnder;
    min-width: 90%;
    max-width: 96%;
    max-height: 96%;
    overflow: auto;
    z-index: 3 !important; /* border-radius:2rem 0 0 .5rem; */
    table {
      width: 100%;
    }
    td,
    th {
      padding: 1rem;
    }
    .titleDescription {
      padding-left: 0.8rem;
      font-size: 1rem;
      width: 70vw;
      h4 {
        text-align: left;
        padding: 0;
      }
    }
    thead {
      font-size: 2.5rem;
      background: rgba(76, 76, 76, 0.98);
      color: white;
      span {
        background: radial-gradient(var(--color) 50%, transparent 50%);
        display: inline-block;
        width: 1.7em;
        height: 1.7em;
        text-indent: 0.55em;
        box-sizing: border-box;
        font-size: 2.8rem;
        margin-right: -0.55rem;
        transform: rotate(4deg);
      }
      th {
        position: relative;
        div.closeTable {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 2rem;
          font-size: 1.25rem;
          cursor: pointer;
        }
      }
    }
    tbody {
      font-size: 1.1rem;
      td {
        border: 2px solid white;
        border-right: none; // otherwise scroll appears
        &:nth-of-type(1) {
          color: rgba(255, 255, 255, 0.95);
        }
        &:nth-of-type(2) {
          background: rgba(252, 236, 232, 0.95);
        }
      }
    }
    td {
      background: var(--color);
    }
    &::-webkit-scrollbar-thumb {
      border-left: initial;
    }
    &.Powered {
      --color: rgba(234, 0, 30, 1);
    }
    &.Leader {
      --color: #ff7111;
      span {
        text-indent: 0.58em;
      }
      &::-webkit-scrollbar-thumb {
        background: #ff7111;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #dd5e0a;
      }
    }
    &.Accurate {
      --color: #35b1ab;
      span {
        text-indent: 0.5em;
      }
      &::-webkit-scrollbar-thumb {
        background: #35b1ab;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #28928d;
      }
    }
    &.Easy {
      --color: #2e75b6;
      span {
        text-indent: 0.53em;
        margin-left: -0.5rem;
        margin-right: initial;
        padding-top: 0.2rem;
      }
      .Eas {
        padding-left: 0.8rem;
        display: inline;
      }
      &::-webkit-scrollbar-thumb {
        background: #2e75b6;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #1f5a92;
      }
    }
  }
}

// make wrong loading appear after a while; this element IS NOT inside _container
.failedLoading {
  animation: fadeFailedLoading 0.3s ease 0.5s both;
}
@keyframes fadeFailedLoading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
