// Some variables
$themeColor: #ee002f;

// Shorten some selector
$hf: header, footer;
$h0: h1, h2, h3, h4;
$a: "&, &:link, &:visited"; // More complex selector must be a string

// Flex Mixin
@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

// Flex wrap mixin
@mixin flex-wrap($direction: row, $justify: center, $align: center) {
  @include flex($direction, $justify, $align);
  flex-wrap: wrap;
}

// Full Mixin
@mixin full($height: 100%) {
  width: 100%;
  height: $height;
}

// Full with color
@mixin full-bg($height: 100%, $bg: $themeColor, $color: white) {
  @include full($height);
  background: $bg;
  color: $color;
}

// Move flex child to direction
@mixin moveTo($dir, $margin: 1.3rem) {
  margin-#{$dir}: $margin;
  @if $dir == left {
    margin-right: auto;
  } @else {
    margin-left: auto;
  }
}

// Center overlay
@mixin centerOverlay($dir: both, $pos: absolute) {
  position: $pos;
  z-index: 9999;
  @if $dir == left {
    #{$dir}: 50%;
    transform: translate(-50%);
  } @else if $dir == top {
    #{$dir}: 50%;
    transform: translateY(-50%);
  } @else if $dir == both {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

// Obscure under with box box-shadow
@mixin obscureUnder($color: rgba(0, 0, 0, 0.7)) {
  box-shadow: 0 0 4000px 4000px $color;
}

// Make links white and underlined
%links {
  #{$a} {
    color: white;
    text-decoration: underline;
  }
}
