@import "./vars-mixins.scss";
#outlet {
  padding: 0;
}
// Profile
._container {
  // remove margin and padding for avoid interrupting gamePreviews bgcolor
  margin: 0;
  padding: 0;
  //box-sizing:border-box;

  h1.addMargin {
    margin: 3rem 0 -1.4rem 0;
    // clear after in flex layout
    &::after {
      content: "";
      display: block;
      width: 70vw;
      height: 1px;
    }
  }

  .graySection {
    //background:#f6f6f6;
    @include flex-wrap;
    margin-top: 0rem;
    padding-bottom: 2rem;
    width: 100%;
    h1 {
      margin-top: 1rem;
      text-align: center;
    }

    // game preview box
    .gamePreview {
      @include flex-wrap;
      margin: 1rem 0;
      width: 80%;
      max-width: 1000px;
      padding: 2rem;
      h4,
      h3 {
        width: 100%;
        color: $themeColor;
        text-align: center;
        margin: 2rem 0 0.1rem 0;
        font-weight: bold;
      }
      img {
        width: 9rem;
        margin-bottom: 1rem;
        max-width: 90vw;
      }
      p {
        padding: 0 1.2rem;
        flex: 1;
        text-align: justify;
        min-width: 12rem;
        background: rgba(255, 255, 255, 0.6);
      }
      &:last-of-type {
        padding-bottom: 2rem;
      }

      .gameCaption {
        @include flex-wrap(column);
        button {
          font-weight: 550;
          font-size: 1.1rem;
        }
      }

      .gameCaption + p {
        @include flex;
        text-align: justify;
        margin-top: 2rem;
      }
    }
  }

  // put information above the page
  .overlayGame {
    @include flex-wrap(column);
    @include obscureUnder;
    &.resultParent {
      // using justify center on the parent cuts the top of the child
      overflow: auto;
      justify-content: flex-start;
      .scrollResult {
        @include flex(column);
        // instead, use margin on the child
        margin-top: auto;
        margin-bottom: auto;
        svg {
          width: 35rem;
          max-width: 95%;
          margin-bottom: 1rem;
          margin-top: -3.5rem;
        }
        .numericRank {
          font-size: 1.2rem;
        }
      }
    }
    position: fixed;
    width: 94%;
    height: 92%;
    left: 3%;
    top: 4%;
    background: white;
    border-radius: 2rem 0.5rem 0 0.5rem;
    h2 {
      color: $themeColor;
    }
    .closeOverlay {
      position: absolute;
      top: 0.2rem;
      right: 1.1rem;
      font-size: 1.5rem;
      cursor: pointer;
      transform: scale(0.6);
      transform-origin: 100% 0%;
    }
    .counterGame,
    .liberoGame {
      color: black;
      background: #fff;
      padding: 0.5rem;
      width: 100%;
      text-align: center;
    }
    .liberoGame {
      margin-top: 0.5rem;
      width: 90%;
    }
    .instructions {
      /*box-shadow:0px 4px 10px 1px #bbb;*/
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      flex: 1;
      margin: 1rem 0 0 0;
      padding: 1rem 2rem;
      text-align: center;
      overflow: auto;
      li {
        display: block;
      }
      img {
        margin: 2rem 0;
        max-width: #{"min(100%, 500px, 50vh)"}; /* this interpolation syntax is necessary because sass tries to use its built in function min */
      }
      color-blue {
        color: #19c0e0;
        font-weight: bold;
      }
      color-red {
        color: #ff394b;
        font-weight: bold;
      }
    }
    & > img,
    .scrollResult > img {
      width: 9rem;
      margin: 1rem 0;
    }
    .resultGame {
      margin: 1rem 0;
      &,
      div {
        text-align: center;
      }
    }
  }

  // buttons with arrow
  .gamePreview,
  .overlayGame {
    button:not(.noArrowIcon) {
      margin-top: -0.1rem;
      position: relative;
      &::after {
        content: "";
        filter: invert(1); // make white
        position: absolute;
        top: 0.17rem;
        right: 0.1rem;
        width: 1.8rem;
        height: 1.8rem;
        background-image: url("../img/play.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      &.playVariant::after {
        right: 0.25rem;
      }
      &.grafico-icon::after {
        background-image: url("../img/grafico-icon.svg");
        right: 0.3rem;
        filter: none;
      }
      &.btn-lg::after {
        top: 0.34rem;
        right: 0.2rem;
      }
    }
    button.onlyShowResults {
      width: 8.5rem;
      height: 2.5rem;
      padding-left: 1.5rem;
      &.retryVariant {
        width: 8.7rem;
        padding-left: 1.3rem;
        &::after {
          top: 0.25rem;
          right: 0.3rem;
        }
      }
    }
  }

  // iframe with game
  iframe {
    width: 99.9%;
    flex: 1;
    border: none;
  }
}

// ugly fix: .instructions img for edge and firefox
@supports (-ms-ime-align: auto) {
  .instructions img {
    max-width: 25rem !important;
  }
}
@supports (-moz-appearance: none) {
  .instructions img {
    width: 25rem !important;
  }
}
